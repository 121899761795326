import { useState, useEffect } from "react";

import './Preview.css';

import { GetDataPage } from "interface/Data";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { useNavigate } from 'react-router-dom';

import Comp_Footer from "components/Footer";

export default function Page_Preview(props){

    const navigate = useNavigate();
    const [ showUrl, setShowUrl ] = useState(props.url);
    const [ page, setPage ] = useState(GetListPag('page'));
    const [ idioma, setIdioma ] = useState(GetListPag('idioma'));
    const [ showData, setShowData ] = useState(InitialData('current'));

    const [ listDataPage, setListDataPage ] = useState(ShowDataIdioma());

    const [ nextPortfolio, setNextPortfolio ] = useState(InitialData('next'));
    const [ nextFile, setNextFile ] = useState(InitialData('file'));

    function InitialData(type){
        const newData = GetDataPage('portfolio')['portfolio'].find(item => item.url == props.url);
        if(newData){
            if(type == 'current'){
                return newData;

            }else {                
                let indexCount = GetDataPage('portfolio')['portfolio'].findIndex(item => item.id_portfolio == newData['id_portfolio']);
                
                if((indexCount + 1) == GetDataPage('portfolio')['portfolio'].length){
                    const showNextPortfolio = GetDataPage('portfolio')['portfolio'][0];
                    if(showNextPortfolio){
                        if(type == 'next'){
                            return showNextPortfolio;
                        }else {
                            return showNextPortfolio.data.find(item => item.idioma == 'pt_br');
                        }
                    }else {
                        navigate("/home");
                        SetListPag('page', 'home');
                    }
                }else {                    
                    const showNextPortfolio = GetDataPage('portfolio')['portfolio'][indexCount + 1];
                    if(showNextPortfolio){
                        if(type == 'next'){
                            return showNextPortfolio;
                        }else {
                            return showNextPortfolio.data.find(item => item.idioma == 'pt_br');
                        }
                    }else {
                        navigate("/home");
                        SetListPag('page', 'home');
                    }
                }
            }
        }else {
            navigate("/home");
            SetListPag('page', 'home');
        }
    }

    function ShowDataIdioma(){
        const newData = GetDataPage('portfolio')['portfolio'].find(item => item.url == props.url);
        if(newData){
            const newIdioma = newData['data'].find(item => item.idioma == idioma);
            return newIdioma;
            
        }else {
            navigate("/home");
            SetListPag('page', 'home');
        }
    }
    
    function ShowVideo(type, video){
        switch (type) {
            case 'Vimeo':
                return <iframe className="div_video" src={ "https://player.vimeo.com/video/" + video + "" } frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" />;

            case 'Drive':
                let linkDrive = video.split('/');
                return <iframe className="div_video" src={ "https://drive.google.com/file/d/" + linkDrive[5] + "/preview" } frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" />;    
            
            case "Youtube":
                return <iframe className="div_video" src={ "https://www.youtube.com/embed/" + video + "" } frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" />;
        }
    }

    function OpenFile(value){
        SetModalData('ShowFile', { "file": value, "color": showData.bg_color });
        SetModalState('ShowFile', true);
    }

    function ScrollAnimation(){
        document.querySelectorAll('.efect_translate_y').forEach(element => {
            if(element.getBoundingClientRect().top <= 500){
                element.classList.add('efect_translate_visible');
            }
        });
    }

    function RemoveClass(){
        // const divs = document.querySelectorAll('div');
        // divs.forEach(div => {
        //     div.classList.remove('efect_translate_visible');
        // });
        window.location.href = nextPortfolio.url;
    }

    useEffect(()=>{
        RegisterListPag('page', setPage);
        RegisterListPag("idioma", setIdioma);
        document.title = 'Projeto ' + showData.title;
        if(page == 'preview'){
            document.body.style.backgroundColor = showData.color;
        }

        document.addEventListener('scroll', (event)=>{
            ScrollAnimation();
        });
    }, []);

    useEffect(()=>{        
        setShowUrl(props.url);
        setShowData(InitialData('current'));
        setListDataPage(ShowDataIdioma());

        setNextPortfolio(InitialData('next'));
        setNextFile(InitialData('file'));
    }, [showUrl]);
    
    return(
        <div className="Page_Preview" style={ { backgroundColor: showData.color } }>   
            <div className="div_initial">
                <img alt="img" src={ listDataPage.file } className="img_full" />
            </div>
            <div className="div_line">
                <div className="line bg_1" style={ { backgroundColor: showData.color } } />
                <div className="line bg_2" style={ { backgroundColor: showData.color } } />
                <div className="line bg_3" style={ { backgroundColor: showData.color } } />
                <div className="line bg_4" style={ { backgroundColor: showData.color } } />
            </div>       

            <div className="div_data_project contents">
                <div className="div_data show_data_project efect_translate_y">
                    <div className="data_project show_project_align">
                        <div className="title" dangerouslySetInnerHTML={ { __html: listDataPage.project ? listDataPage.project.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                        {
                            listDataPage.subtitle !='' ? 
                            <div className="subtitle" dangerouslySetInnerHTML={ { __html: listDataPage.subtitle ? listDataPage.subtitle.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } /> : null
                        }
                    </div>
                </div>
                <div className="div_data align_top efect_translate_y">
                    <div className="data_project data_client">
                        <div className="name_cliente">
                            CLIENT: <span className="name_project">{ showData.client }</span>
                        </div>
                        {
                            showData.year !='' ? 
                            <div className="name_cliente">
                                YEAR: <span className="name_project">{ showData.year }</span>
                            </div> : null
                        }                        
                        {
                            listDataPage.type !='' ? 
                            <div className="name_cliente">
                                TYPE: <span className="name_project">{ listDataPage.type }</span>
                            </div> : null
                        }                        
                    </div>
                    <div className="data_project text_project show_text" dangerouslySetInnerHTML={ { __html: listDataPage.text ? listDataPage.text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                </div>
            </div> 
            {
                listDataPage.contents.map((key, index)=>{
                    return(
                        <div className={ key.page_full == true ? "show_data_page page_full" : "show_data_page" } key={ index }>
                            {
                                key.data_contents.map((key_1, index_1)=>{
                                    switch (key_1.type) {
                                        case 'img':
                                            return(
                                                <div className="div_contents efect_translate_y" key={ index_1 }>
                                                    <img alt={ key_1.name } src={ key_1.file } className="show_img" />
                                                </div>
                                            )

                                        case 'text':
                                            return(
                                                <div className="div_contents efect_translate_y" key={ index_1 }>
                                                    <div className="div_text">
                                                        <div className="title">{ key_1.title }</div>
                                                        <div className="text" dangerouslySetInnerHTML={ { __html: key_1.text ? key_1.text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                                    </div>
                                                </div>
                                            )

                                        case 'video':
                                            return(
                                                <div className="div_contents efect_translate_y" key={ index_1 }>
                                                    { ShowVideo(key_1.type_video, key_1.link) }
                                                </div>
                                            )
                                    }
                                })
                            }
                        </div>
                    )
                })
            }

            {
                listDataPage.datasheet ? 
                <div className="datasheet efect_translate_y" style={ { padding: "60px 0px" } }>
                    <div className="data_project text_project" dangerouslySetInnerHTML={ { __html: listDataPage .datasheet ? listDataPage.datasheet.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                </div> : null
            }
            
            <Comp_Footer ReturnTop={ props.ReturnTop } url={ nextPortfolio.url } project={ nextFile.project } subtitle={ nextFile.subtitle } year={ nextPortfolio.year } file={ nextFile.file } setShowUrl={ RemoveClass } />

        </div>
    )
}