import { useState, useEffect } from "react";

import './Header.css';

import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { SvgLogoTipo } from "components/SvgFile";

import { Link } from "react-router-dom";

export default function Comp_Header(props){

    const [ page, setPage ] = useState(GetListPag('page'));
    const [ idioma, setIdioma ] = useState(GetListPag('idioma'));
    const [ namePage, setNamePage ] = useState(GetDataPage('name_menu'));
    const [ showIdioma, setShowIdioma ] = useState(GetDataPage('show_idioma'));

    const [ status, setStatus ] = useState(false);

    function OpenMenu(){
        if(status == true){
            if(document.querySelector('.bg_line_color')){
                document.querySelector('.bg_line_color').classList.remove('bg_top');
                document.querySelector('.bg_line_color').classList.add('bg_bottom');

                document.querySelector('.div_opt_menu').classList.remove('name_menu_show');  
                document.querySelector('.div_opt_menu').classList.add('name_menu_hide'); 
            }

            setTimeout(() => {
                if(document.querySelector('.line_color_1')){
                    document.querySelector('.line_color_1').classList.remove('animation_top');
                    document.querySelector('.line_color_1').classList.add('animation_bottom'); 
                }
            }, 300);
            setTimeout(() => {
                if(document.querySelector('.line_color_2')){
                    document.querySelector('.line_color_2').classList.remove('animation_top');
                    document.querySelector('.line_color_2').classList.add('animation_bottom'); 
                }
            }, 350);
            setTimeout(() => {
                if(document.querySelector('.line_color_3')){
                    document.querySelector('.line_color_3').classList.remove('animation_top');
                    document.querySelector('.line_color_3').classList.add('animation_bottom');
                }
            }, 400);
            setTimeout(() => {
                if(document.querySelector('.line_color_4')){
                    document.querySelector('.line_color_4').classList.remove('animation_top');
                    document.querySelector('.line_color_4').classList.add('animation_bottom');          
                }               
            }, 450);

            setTimeout(() => {
                document.querySelector('.bg_line_color').classList.remove('bg_bottom');
                document.querySelector('.div_opt_menu').classList.remove('name_menu_hide');     
            }, 1000);

            setStatus(false);
        }else {
            // document.getElementById('root').style.overflow = 'hidden';
            if(document.querySelector('.bg_line_color')){
                document.querySelector('.bg_line_color').classList.remove('bg_bottom');
                document.querySelector('.bg_line_color').classList.add('bg_top');
            }

            setTimeout(() => {
                if(document.querySelector('.line_color_1')){
                    document.querySelector('.line_color_1').classList.remove('animation_bottom');
                    document.querySelector('.line_color_1').classList.add('animation_top');
                }
            }, 300);
            setTimeout(() => {
                if(document.querySelector('.line_color_2')){
                    document.querySelector('.line_color_2').classList.remove('animation_bottom');
                    document.querySelector('.line_color_2').classList.add('animation_top');
                }
            }, 350);
            setTimeout(() => {
                if(document.querySelector('.line_color_3')){
                    document.querySelector('.line_color_3').classList.remove('animation_bottom');
                    document.querySelector('.line_color_3').classList.add('animation_top');
                }
            }, 400);
            setTimeout(() => {
                if(document.querySelector('.line_color_4')){
                    document.querySelector('.line_color_4').classList.remove('animation_bottom');
                    document.querySelector('.line_color_4').classList.add('animation_top'); 
                }
            }, 450);

            setTimeout(() => {
                document.querySelector('.div_opt_menu').classList.remove('name_menu_hide');  
                document.querySelector('.div_opt_menu').classList.add('name_menu_show'); 
            }, 600);

            setStatus(true);
        }
    }
    
    useEffect(()=>{
        RegisterListPag('page', setPage);
        RegisterListPag('idioma', setIdioma);
        RegisterDataPage('name_menu', setNamePage);
        RegisterDataPage('show_idioma', setShowIdioma);
    }, []);

    return(
        <div className="Comp_Header">
            <div className="contents">
                <div className="div_logo">
                    <Link to="/" onClick={ ()=>{  props.ReturnTop('home') } }>
                        {
                            status == true ?
                            <SvgLogoTipo className="logotipo" color="#2d3c53" /> :
                            <SvgLogoTipo className="logotipo" color="#ffffff" />
                        }
                    </Link>
                </div>

                <div className="div_show_opt_menu">
                    <div className="div_icon_idioma">
                        {
                            showIdioma['pt_br'] == false ? null : 
                            <div className={ 
                                status == true ? 
                                idioma == 'pt_br' ? "show_icon show_active show_icon_active" : "show_icon show_icon_active" :
                                idioma == 'pt_br' ? "show_icon show_active" : "show_icon"
                            } onClick={ ()=>{ SetListPag('idioma', 'pt_br') } }>
                                pt
                            </div>
                        }
                        {
                            showIdioma['en'] == false ? null : 
                            <div className={ 
                                status == true ? 
                                idioma == 'en' ? "show_icon show_active show_icon_active" : "show_icon show_icon_active" :
                                idioma == 'en' ? "show_icon show_active" : "show_icon"
                            } onClick={ ()=>{ SetListPag('idioma', 'en') } }>
                                en
                            </div>
                        }
                        {
                            showIdioma['es'] == false ? null : 
                            <div className={ 
                                status == true ? 
                                idioma == 'es' ? "show_icon show_active show_icon_active" : "show_icon show_icon_active" :
                                idioma == 'es' ? "show_icon show_active" : "show_icon"
                            } onClick={ ()=>{ SetListPag('idioma', 'es') } }>
                                es
                            </div>
                        }
                    </div>
                    <div className="div_line_menu" onClick={ ()=>{ OpenMenu(); } }>
                        <div className={ status == true ? "line_1_menu line_1_open" : "line_1_menu line_close" } />
                        <div className={ status == true ? "line_2_menu line_2_open" : "line_2_menu line_close" } />
                    </div>
                </div>

                <div className="bg_line_color">
                    <div className="line_color line_color_1">
                        <div className="data_address">
                            <div className="title">WE ARE ALWAYS HERE</div>
                            <div className="text_address">Av. lguaçu, 2947, 17º andar - Curitiba - Paraná - Brasil</div>
                            <div className="text_address">+55 (41) 99258-3829</div>
                        </div>
                    </div>
                    <div className="line_color line_color_2">
                        <div className="div_opt_menu">
                            {
                                namePage.map((elem, index)=>{
                                    return(
                                        <Link to={ "/" + elem.href } onClick={ ()=>{  props.ReturnTop('home'); OpenMenu(); } } className={ page == elem.href ? "name_menu menu_active" : "name_menu" } key={ index } data-text={ elem.title[idioma] }>
                                            { elem.title[idioma] }
                                        </Link>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="line_color line_color_4" />
                </div>
            </div>
        </div>
    )
}