import { useState, useEffect } from "react";

import './Spotlight.css';

import { GetListPag, RegisterListPag } from "interface/Page";

import Mask from "components/Mask";

import { SvgSetaRight } from "components/SvgFile";
import { Link } from "react-router-dom";

export default function Comp_Spotlight(props){

    const [ idioma, setIdioma ] = useState(GetListPag('idioma'));
    const [ showData, setShowData ] = useState(InitialData('current'));

    const [ listBtn, setListBtn ] = useState(ShowDataIdioma());

    const [ colorTop, setColorTop ] = useState(InitialData('colorTop'));
    const [ colorBottom, setColorBottom ] = useState(InitialData('colorBottom'));

    function InitialData(type){
        if(type == 'current'){
            const newData = props.data.find(item => item.id == props.id);
            return newData;
        }else {
            if(type == 'colorTop'){
                if((props.index - 1) >= 0){
                    const newData = props.data[(props.index - 1)];
                    return newData.color_page[idioma];
                }else {
                    const newData = props.data.find(item => item.id == props.id);
                    return newData.color_page[idioma];
                }

            }else if(type == 'colorBottom'){
                if((props.index + 1) < props.data.length){
                    const newData = props.data[(props.index + 1)];
                    return newData.color_page[idioma];
                }else {
                    const newData = props.data.find(item => item.id == props.id);
                    return newData.color_page[idioma];
                }
            }
        }
    }

    function ShowDataIdioma(){
        const dataPortfolio = [];
        const newData = showData.list_btn.filter(item => item.idioma == idioma);
        switch (newData.length) {
            case 1:
                    newData.map((key, index)=>{
                        dataPortfolio.push(key);
                    })
                    newData.map((key, index)=>{
                        dataPortfolio.push(key);
                    })
                    newData.map((key, index)=>{
                        dataPortfolio.push(key);
                    })
                    newData.map((key, index)=>{
                        dataPortfolio.push(key);
                    })
                    newData.map((key, index)=>{
                        dataPortfolio.push(key);
                    })
                break;
            case 2:
                    newData.map((key, index)=>{
                        dataPortfolio.push(key);
                    })
                    newData.map((key, index)=>{
                        dataPortfolio.push(key);
                    })
                    newData.map((key, index)=>{
                        dataPortfolio.push(key);
                    })
                    newData.map((key, index)=>{
                        dataPortfolio.push(key);
                    })
                break;
            case 3:
                    newData.map((key, index)=>{
                        dataPortfolio.push(key);
                    })
                    newData.map((key, index)=>{
                        dataPortfolio.push(key);
                    })
                    newData.map((key, index)=>{
                        dataPortfolio.push(key);
                    })
                break;
            case 4:
                    newData.map((key, index)=>{
                        dataPortfolio.push(key);
                    })
                    newData.map((key, index)=>{
                        dataPortfolio.push(key);
                    })
                    newData.map((key, index)=>{
                        dataPortfolio.push(key);
                    })
                break;
            default:
                    newData.map((key, index)=>{
                        dataPortfolio.push(key);
                    })
                    newData.map((key, index)=>{
                        dataPortfolio.push(key);
                    })
                break
        }
        return dataPortfolio;
    }
    
    function AltPosition_prev(){
        var stream = document.querySelector('.div_carousel');
        var items = document.querySelectorAll('.gallery__item');
        
        stream.insertBefore(items[items.length - 1], items[0]);
        items = document.querySelectorAll('.gallery__item');
    }
    
    function AltPosition_next(){
        var stream = document.querySelector('.div_carousel');
        var items = document.querySelectorAll('.gallery__item');
        
        stream.appendChild(items[0]);
        items = document.querySelectorAll('.gallery__item');
    }

    function ScrollAnimation(){
        document.querySelectorAll('.efect_translate_y').forEach(element => {
            if(element.getBoundingClientRect().top <= 700){
                element.classList.add('efect_translate_visible');
            }
        });
    }

    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
        if(props.index !=0){
            document.addEventListener('scroll', (event)=>{
                ScrollAnimation();
            });
        }
    }, []);
    
    return(
        <div className="Comp_Spotlight">
            {
                showData.mask_top[idioma] == 0 ? null : <Mask className="mask_top" color={ colorTop } />
            }

            {
                showData.mask_bottom[idioma] == 0 ? null : <Mask className="mask_bottom" color={ colorBottom } />
            }

            <div className={ props.index !=0 ? "show_title efect_translate_y" : "show_title" }>
                <div className="div_title">
                    <div className="title_page">PORTFÓLIO</div>
                    <div className="subtitle_page">SPOTLIGHT</div>
                </div>
            </div>

            <div className="show_spotlight">
                <div className="gallery__prev" onClick={ ()=>{ AltPosition_prev() } }>
                    <div className="icons_carousel_prev">
                        <SvgSetaRight color="#ffffff" className="icons_galerry icons_galerry_next" />
                    </div>
                </div>
                <div className="gallery__next" onClick={ ()=>{ AltPosition_next() } }>
                    <div className="icons_carousel_next">
                        <SvgSetaRight color="#ffffff" className="icons_galerry" />
                    </div>
                </div>

                <div className={ props.index !=0 ? "div_carousel efect_translate_y" : "div_carousel" }>
                    {
                        listBtn.map((elem, index)=>{
                            return (    
                                <div className="gallery__item" key={ index }>
                                    <div className="show_gallery_data">
                                        <div className="div_img">
                                            <div className="div_name_img">
                                                <div className="name_project" data-text={ elem.title }>
                                                    { elem.title }
                                                </div>
                                            </div>
                                            <div className="btn_gallery">
                                                <div className="title_btn" dangerouslySetInnerHTML={ { __html: elem.subtitle ? elem.subtitle.replaceAll('&#34;', '"') : "" } } />

                                                <Link to={ "/" + elem.url } onClick={ ()=>{  props.ReturnTop('preview') } } className="show_btn">
                                                    Ver
                                                </Link>
                                            </div>
                                            <div className="div_show_color">
                                                <div className="div_color_clip" />
                                                <div className="div_color_blur" id={ "blur_" + index } />
                                            </div>
                                            <img alt={ elem.title } src={ elem.file } className="img_example" />
                                        </div>
                                    </div>
                                </div> 
                            )
                        })
                    } 
                </div>
            </div>
        </div>
    )
}