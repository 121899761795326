import { useState, useEffect } from "react";

import './Clients.css';

import { GetListPag, RegisterListPag } from "interface/Page";

import Mask from "components/Mask";

export default function Comp_Clients(props){

    const [ idioma, setIdioma ] = useState(GetListPag('idioma'));
    const [ showData, setShowData ] = useState(InitialData('current'));

    const [ colorTop, setColorTop ] = useState(InitialData('colorTop'));
    const [ colorBottom, setColorBottom ] = useState(InitialData('colorBottom'));

    const [ list1, setList1 ] = useState(ListLogo(0,4));
    const [ list2, setList2 ] = useState(ListLogo(5,9));
    const [ list3, setList3 ] = useState(ListLogo(10,14));
    const [ list4, setList4 ] = useState(ListLogo(15,19));

    function InitialData(type){
        if(type == 'current'){
            const newData = props.data.find(item => item.id == props.id);
            return newData;
        }else {
            if(type == 'colorTop'){
                if((props.index - 1) >= 0){
                    const newData = props.data[(props.index - 1)];
                    return newData.color_page[idioma];
                }else {
                    const newData = props.data.find(item => item.id == props.id);
                    return newData.color_page[idioma];
                }

            }else if(type == 'colorBottom'){
                if((props.index + 1) < props.data.length){
                    const newData = props.data[(props.index + 1)];
                    return newData.color_page[idioma];
                }else {
                    const newData = props.data.find(item => item.id == props.id);
                    return newData.color_page[idioma];
                }
            }
        }
    }

    function ListLogo(numb_1, numb_2){
        const addData = [];
        const newData = showData.logo.filter(item => item.idioma == idioma);
        newData.map((key, index)=>{
            if(index >= numb_1 && index <= numb_2){
                addData.push(key);
            }
        })
        return addData;
    }

    function ScrollAnimation(){
        document.querySelectorAll('.efect_translate_y').forEach(element => {            
            if(element.getBoundingClientRect().top <= 500){
                element.classList.add('efect_translate_visible');
            }
        });
    }

    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
        if(props.index !=0){
            document.addEventListener('scroll', (event)=>{
                ScrollAnimation();
            });
        }
    }, []);
    
    return(
        <>
            {
                showData.mask_top[idioma] == 0 ? null : <Mask className="mask_top" color={ colorTop } />
            }

            {
                showData.mask_bottom[idioma] == 0 ? null : <Mask className="mask_bottom" color={ colorBottom } />
            }

            <div className="Comp_Clients">
                <div className="contents">
                    <div className="div_text">
                        <div className="show_text">
                            <div className={ props.index !=0 ? "subtitle efect_translate_y" : "subtitle" }>{ showData.subtitle[idioma] }</div>
                            <div className={ props.index !=0 ? "title efect_translate_y" : "title" } dangerouslySetInnerHTML={ { __html: showData.title[idioma] ? showData.title[idioma].replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                            {
                                showData.text[idioma] !='' ? 
                                <div className={ props.index !=0 ? "div_show_text efect_translate_y" : "div_show_text" }>
                                    <div className="text" dangerouslySetInnerHTML={ { __html: showData.text[idioma] ? showData.text[idioma].replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                </div> : null
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="List_All_Logo">                
                <div className={ props.index !=0 ? "line_serv efect_translate_y" : "line_serv" }>
                    {
                        list1.map((elem, index)=>{
                            return(
                                <div className="div_logo_client" key={ index }>
                                    <img alt={ elem.name } src={ elem.logo } className="logo_client" />
                                </div>
                            )
                        })
                    }
                </div>
                <div className={ props.index !=0 ? "line_serv efect_translate_y" : "line_serv" }>
                    {
                        list2.map((elem, index)=>{
                            return(
                                <div className="div_logo_client" key={ index }>
                                    <img alt={ elem.name } src={ elem.logo } className="logo_client" />
                                </div>
                            )
                        })
                    }
                </div>
                <div className={ props.index !=0 ? "line_serv efect_translate_y" : "line_serv" }>
                    {
                        list3.map((elem, index)=>{
                            return(
                                <div className="div_logo_client" key={ index }>
                                    <img alt={ elem.name } src={ elem.logo } className="logo_client" />
                                </div>
                            )
                        })
                    }
                </div>
                <div className={ props.index !=0 ? "line_serv efect_translate_y" : "line_serv" }>
                    {
                        list4.map((elem, index)=>{
                            return(
                                <div className="div_logo_client" key={ index }>
                                    <img alt={ elem.name } src={ elem.logo } className="logo_client" />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}