let ListPag = {
    "page" : 'home',
    "idioma" : 'pt_br',
    "id_preview" : 0
};

let NotifyListPag = {
    "page" : [],
    "idioma" : [],
    "id_preview" : []
}

export function SetListPag(key, value){
    ListPag[key] = value;
    NotifyListPag[key].forEach(element => {
        element(value);
    });
}

export function GetListPag(key){
    return ListPag[key];
}

export function RegisterListPag(key, value){
    if(!NotifyListPag[key]){
        NotifyListPag[key] = [];
    }
    NotifyListPag[key].push(value);
}
