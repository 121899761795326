import { useState, useEffect, useRef } from "react";

import './Text.css';

import { GetListPag, RegisterListPag } from "interface/Page";

import Mask from "components/Mask";

export default function Comp_Text(props){

    const height = useRef();

    const [ idioma, setIdioma ] = useState(GetListPag('idioma'));
    const [ showData, setShowData ] = useState(InitialData('current'));

    const [ colorTop, setColorTop ] = useState(InitialData('colorTop'));
    const [ colorBottom, setColorBottom ] = useState(InitialData('colorBottom'));

    function InitialData(type){
        if(type == 'current'){
            const newData = props.data.find(item => item.id == props.id);
            return newData;
        }else {
            if(type == 'colorTop'){
                if((props.index - 1) >= 0){
                    const newData = props.data[(props.index - 1)];
                    return newData.color_page[idioma];
                }else {
                    const newData = props.data.find(item => item.id == props.id);
                    return newData.color_page[idioma];
                }

            }else if(type == 'colorBottom'){
                if((props.index + 1) < props.data.length){
                    const newData = props.data[(props.index + 1)];
                    return newData.color_page[idioma];
                }else {
                    const newData = props.data.find(item => item.id == props.id);
                    return newData.color_page[idioma];
                }
            }
        }
    }

    function ScrollAnimation(){
        document.querySelectorAll('.efect_translate_y').forEach(element => {
            if(element.getBoundingClientRect().top <= 500){
                element.classList.add('efect_translate_visible');
            }
        });
    }

    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
        if(props.index !=0){
            document.addEventListener('scroll', (event)=>{
                ScrollAnimation();
            });
        }
    }, []);
    
    return(
        <div className="Comp_Text">
            {
                showData.mask_top[idioma] == 0 ? null : <Mask className="mask_top" color={ colorTop } />
            }

            {
                showData.mask_bottom[idioma] == 0 ? null : <Mask className="mask_bottom" color={ colorBottom } />
            }

            <div className="div_text div_right">
                <div className={ props.index !=0 ? "show_text efect_translate_y" : "show_text" }>
                    {
                        showData.subtitle[idioma] == "" ? null : 
                        <div className="subtitle">{ showData.subtitle[idioma] }</div>
                    }
                    {
                        showData.title[idioma] == "" || showData.title[idioma] == "<p><br></p>" ? null : 
                        <div className="title" dangerouslySetInnerHTML={ { __html: showData.title[idioma] ? showData.title[idioma].replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                    }
                    {
                        showData.text[idioma] !='' ? 
                        <div className={ props.temp == 1 ? "div_show_text new_align_text" : "div_show_text" }>
                            <div className={ props.temp == 1 ? "text page_text new_text_css" : "text page_text" } dangerouslySetInnerHTML={ { __html: showData.text[idioma] ? showData.text[idioma].replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                        </div> : null
                    }
                </div>
            </div>
        </div>
    )
}