
let DataPage = {
    "show_idioma" : {},
    "name_menu"   : [],
    "home"        : {},
    "works"       : {},
    "about"       : {},
    "contact"     : {},
    "preview"     : {}
};

let NotifyDataPage = {
    "show_idioma" : [],
    "name_menu"   : [],
    "home"        : [],
    "works"       : [],
    "about"       : [],
    "contact"     : [],
    "preview"     : []
}

export function SetListData(value) {
    DataPage = value;
    NotifyDataPage["show_idioma"].forEach(element => {
        element(value['show_idioma']);
    });
    NotifyDataPage["name_menu"].forEach(element => {
        element(value['name_menu']);
    });
    NotifyDataPage["home"].forEach(element => {
        element(value['home']);
    });
    NotifyDataPage["works"].forEach(element => {
        element(value['works']);
    });
    NotifyDataPage["about"].forEach(element => {
        element(value['about']);
    });
    NotifyDataPage["contact"].forEach(element => {
        element(value['contact']);
    });
    NotifyDataPage["preview"].forEach(element => {
        element(value['preview']);
    });
}

export function SetListDataSingle(key, value) {
    DataPage[key] = value;
    NotifyDataPage[key].forEach(element => {
        element(value);
    });
}

export function GetDataPage(key){
    return DataPage[key];
}

export function RegisterDataPage(key, value){
    if(!NotifyDataPage[key]){
        NotifyDataPage[key] = [];
    }
    NotifyDataPage[key].push(value);
}
