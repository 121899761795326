import { useState, useEffect } from "react";

import './OurServices.css';

import { SvgSetaRight } from "components/SvgFile";

import Mask from "components/Mask";

import { GetListPag, RegisterListPag } from "interface/Page";

export default function Comp_OurServices(props){

    const [ idioma, setIdioma ] = useState(GetListPag('idioma'));
    const [ showData, setShowData ] = useState(InitialData('current'));

    const [ opt, setOpt ] = useState('');
    const [ listBtn, setListBtn ] = useState(ShowListBtn());

    const [ colorTop, setColorTop ] = useState(InitialData('colorTop'));
    const [ colorBottom, setColorBottom ] = useState(InitialData('colorBottom'));

    function InitialData(type){
        if(type == 'current'){
            const newData = props.data.find(item => item.id == props.id);
            return newData;
        }else {
            if(type == 'colorTop'){
                if((props.index - 1) >= 0){
                    const newData = props.data[(props.index - 1)];
                    return newData.color_page[idioma];
                }else {
                    const newData = props.data.find(item => item.id == props.id);
                    return newData.color_page[idioma];
                }

            }else if(type == 'colorBottom'){
                if((props.index + 1) <= props.data.length){
                    const newData = props.data[(props.index + 1)];
                    return newData.color_page[idioma];
                }else {
                    const newData = props.data.find(item => item.id == props.id);
                    return newData.color_page[idioma];
                }
            }
        }
    }

    function ShowBtn(value){
        setOpt(value);
    }

    function ShowListBtn(){
        const newData = showData.list_btn.filter(item => item.idioma == idioma);
        return newData;
    }

    function ScrollAnimation(){
        document.querySelectorAll('.efect_translate_y').forEach(element => {
            if(element.getBoundingClientRect().top <= 500){
                element.classList.add('efect_translate_visible');
            }
        });
    }

    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
        if(props.index !=0){
            document.addEventListener('scroll', (event)=>{
                ScrollAnimation();
            });
        }
        
    }, []);

    useEffect(()=>{
        setListBtn(ShowListBtn());
    }, [idioma]);
    
    return(
        <div className="Comp_OurServices">
            {
                showData.mask_top[idioma] == 0 ? null : <Mask className="mask_top" color={ colorTop } />
            }

            {
                showData.mask_bottom[idioma] == 0 ? null : <Mask className="mask_bottom" color={ colorBottom } />
            }

            <div className="contents">

                <div className={ props.index !=0 ? "div_text efect_translate_y" : "div_text" }>
                    <div className="show_text">
                        <div className="subtitle">{ showData.subtitle[idioma] }</div>
                        <div className="title" dangerouslySetInnerHTML={ { __html: showData.title[idioma] ? showData.title[idioma].replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                    </div>
                </div>

                <div className="show_list_btn">
                    <div className="list_btn">
                        <div className={ props.index !=0 ? "text efect_translate_y" : "text" } dangerouslySetInnerHTML={ { __html: showData.text[idioma] ? showData.text[idioma].replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                        {
                            listBtn.map((elem, index)=>{                                                                
                                return(
                                    <div className={ index == 0 ? 
                                        props.index !=0 ? "div_btn btn_space_top efect_translate_y" : "div_btn btn_space_top" 
                                        : 
                                        props.index !=0 ? "div_btn efect_translate_y" : "div_btn"
                                    } key={ index }>
                                        <div className="show_btn" onClick={ ()=>{ ShowBtn(elem.opt == opt ? '' : elem.opt) } }>
                                            <div className="name_btn">
                                                <span className="number_item">0{ index + 1 }</span> 
                                                { elem.name }
                                            </div>
                                            <div className="arrow_btn">
                                                {
                                                    elem.opt == opt ? 
                                                    <SvgSetaRight className="icons arrow_down" color="#ffffff" /> :
                                                    <SvgSetaRight className="icons" color="#ffffff" />
                                                }
                                            </div>
                                        </div>
                                        <div className={ elem.opt == opt ? "show_text text_open" : "show_text" } dangerouslySetInnerHTML={ { __html: elem.opt == opt ? elem.text ? elem.text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" : "" } } />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            
            </div>
        </div>
    )
}