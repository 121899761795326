import './Mask.css';

export default function Mask(props){    
    return(
        <div className={ "div_mask " + props.className }>
            <div className="mask_fixed mask_1" style={ { backgroundColor: props.color } } />
            <div className="mask_fixed mask_2" style={ { backgroundColor: props.color } } />
            <div className="mask_fixed mask_3" style={ { backgroundColor: props.color } } />
            <div className="mask_fixed mask_4" style={ { backgroundColor: props.color } } />
        </div>
    )
}