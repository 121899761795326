import { useState, useEffect } from "react";

import './Portfolio.css';

import Mask from "components/Mask";

import { GetListPag, RegisterListPag } from "interface/Page";

import { Link } from "react-router-dom";

export default function Comp_Portfolio(props){

    const [ idioma, setIdioma ] = useState(GetListPag('idioma'));
    const [ showData, setShowData ] = useState(InitialData('current'));

    const [ listBtn, setListBtn ] = useState(ShowDataIdioma());

    const [ colorTop, setColorTop ] = useState(InitialData('colorTop'));
    const [ colorBottom, setColorBottom ] = useState(InitialData('colorBottom'));
    
    const [ nameBtn, setNameBtn ] = useState([
        {
            "en": "PORTFOLIO",
            "es": "PORTAFOLIO",
            "pt_br": "PORTFÓLIO"
        },
        {
            "en": "MOST RECENT WORKS",
            "es": "OBRAS MAS RECIENTES",
            "pt_br": "TRABALHOS MAIS RECENTES"
        },
        {
            "en": "SEE ALL PORTFOLIO",
            "es": "VER TODO EL PORTAFOLIO",
            "pt_br": "VEJA TODO O PORTFÓLIO"
        }
    ]);

    function InitialData(type){
        if(props.origin == 'portfolio'){              
            return props.data['portfolio'];
        }else {            
            if(type == 'current'){
                const newData = props.data.find(item => item.id == props.id);
                return newData;
            }else {
                if(type == 'colorTop'){
                    if((props.index - 1) >= 0){
                        const newData = props.data[(props.index - 1)];
                        return newData.color_page[idioma];
                    }else {
                        const newData = props.data.find(item => item.id == props.id);
                        return newData.color_page[idioma];
                    }

                }else if(type == 'colorBottom'){
                    if((props.index + 1) < props.data.length){
                        const newData = props.data[(props.index + 1)];
                        return newData.color_page[idioma];
                    }else {
                        const newData = props.data.find(item => item.id == props.id);
                        return newData.color_page[idioma];
                    }
                }
            }
        }
    }

    function ShowDataIdioma(){
        const dataPortfolio = [];
        if(props.origin == 'portfolio'){
            return props.data['portfolio'];
        }else {
            const newData = showData.list_btn.filter(item => item.idioma == idioma);
            switch (newData.length) {
                case 1:
                        newData.map((key, index)=>{
                            dataPortfolio.push(key);
                        })
                        newData.map((key, index)=>{
                            dataPortfolio.push(key);
                        })
                        newData.map((key, index)=>{
                            dataPortfolio.push(key);
                        })
                        newData.map((key, index)=>{
                            dataPortfolio.push(key);
                        })
                        newData.map((key, index)=>{
                            dataPortfolio.push(key);
                        })
                    break;
                case 2:
                        newData.map((key, index)=>{
                            dataPortfolio.push(key);
                        })
                        newData.map((key, index)=>{
                            dataPortfolio.push(key);
                        })
                        newData.map((key, index)=>{
                            dataPortfolio.push(key);
                        })
                    break;
                case 3:
                        newData.map((key, index)=>{
                            dataPortfolio.push(key);
                        })
                        newData.map((key, index)=>{
                            dataPortfolio.push(key);
                        })
                    break;
                default:
                        newData.map((key, index)=>{
                            dataPortfolio.push(key);
                        })
                    break;
            }
        }
        return dataPortfolio;
    }

    function ScrollAnimation(){
        document.querySelectorAll('.efect_translate_y').forEach(element => {
            if(element.getBoundingClientRect().top <= 500){
                element.classList.add('efect_translate_visible');
            }
        });
    }

    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
        if(props.index !=0){
            document.addEventListener('scroll', (event)=>{
                ScrollAnimation();
            });
        }
    }, []);
    
    return(
        <div className="Comp_Portfolio">
            {
                props.origin == 'portfolio' ? null :
                <>                    
                    {
                        showData.mask_top[idioma] == 0 ? null : <Mask className="mask_top" color={ colorTop } />
                    }

                    {
                        showData.mask_bottom[idioma] == 0 ? null : <Mask className="mask_bottom" color={ colorBottom } />
                    }
                    <div className={ props.index !=0 ? "div_title efect_translate_y" : "div_title" }>
                        <div className="title_page">{ nameBtn[0][idioma] }</div>
                        <div className="subtitle_page">{ nameBtn[1][idioma] }</div>
                    </div>
                </>
            }
            <div className={ props.index !=0 ? "list_portfolio efect_translate_y" : "list_portfolio" }>
                {
                    listBtn.map((elem, index)=>{
                        return(
                            <Link to={ "/" + elem.url } onClick={ ()=>{  props.ReturnTop('preview') } } className="div_show_portfolio" key={ index }>
                                <div className="img_">
                                    <img alt={ elem.title } src={ elem.thumbnail } className="show_img" />
                                </div>
                                <div className="show_name">
                                    <div className="name">
                                        { elem.client }
                                    </div>
                                    <div className="name_detalis">
                                        { elem.title }
                                    </div>
                                </div>
                            </Link>
                        )
                    })
                }
            </div>
            {
                props.origin == 'portfolio' ? null :
                <div className="div_btn">
                    <Link to="/works" onClick={ ()=>{  props.ReturnTop('works') } } className="name_btn">
                        { nameBtn[2][idioma] }
                    </Link>
                </div>
            }
        </div>
    )
}