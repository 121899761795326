import { useState, useEffect } from "react";

import './LineFixed.css';

export default function Comp_LineFixed(props){

    return(
        <>
            <div className="line_1" />
            <div className="line_2" />
            <div className="line_3" />
        </>
    )
}