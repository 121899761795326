import Axios from 'axios';

export function Reg_Contact(name, email, phone, subject, text, privacy, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append('type_page', 'contact');
    data.append('name', name);
    data.append('email', email);
    data.append('phone', phone);
    data.append('subject', subject);
    data.append('text', text);
    data.append('privacy', privacy);

    Axios({
        url : process.env.REACT_APP_API_URL + 'send_email.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        console.log(response.data);
        
        if(response.data == 'ok!'){
            CallbackSuccess();
        }else {
            CallbackError();
        }

    }).catch((error)=>{

        CallbackError();
    })
}