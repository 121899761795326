import { useState, useEffect } from "react";

import './ShowNumbers.css';

import { GetListPag, RegisterListPag } from "interface/Page";

import Mask from "components/Mask";
import { SvgSetaRight } from "components/SvgFile";

export default function Comp_ShowNumbers(props){

    const [ idioma, setIdioma ] = useState(GetListPag('idioma'));
    const [ showData, setShowData ] = useState(InitialData('current'));

    const [ colorTop, setColorTop ] = useState(InitialData('colorTop'));
    const [ colorBottom, setColorBottom ] = useState(InitialData('colorBottom'));

    const [ listBtn, setListBtn ] = useState(ShowListBtn());

    const [ animationDuration, setAnimationDuration ] = useState(1);

    let count = 0;
    let elapsedTime = 0;
    let time = 0;

    const [ countYear, setCountYear ] = useState(0);
    
    const [ timeAnimation, setTimeAnimation ] = useState(0);

    const [ countNumb_1, setCountNumb_1 ] = useState(0);
    const [ countNumb_2, setCountNumb_2 ] = useState(0);
    const [ countNumb_3, setCountNumb_3 ] = useState(0);
    const [ countNumb_4, setCountNumb_4 ] = useState(0);

    function InitialData(type){
        if(type == 'current'){
            return props.data.find(item => item.id == props.id);
        }else {
            if(type == 'colorTop'){
                if((props.index - 1) >= 0){
                    const newData = props.data[(props.index - 1)];
                    return newData.color_page[idioma];
                }else {
                    const newData = props.data.find(item => item.id == props.id);
                    return newData.color_page[idioma];
                }

            }else if(type == 'colorBottom'){
                if((props.index + 1) < props.data.length){
                    const newData = props.data[(props.index + 1)];
                    return newData.color_page[idioma];
                }else {
                    const newData = props.data.find(item => item.id == props.id);
                    return newData.color_page[idioma];
                }
            }
        }
    }

    function ShowListBtn(){
        const newData = showData.numbers.filter(item => item.idioma == idioma);
        return newData;
    }

    function ScrollAnimation(){
        document.querySelectorAll('[data-animationcount]').forEach(element => {
            if(element.getBoundingClientRect().top <= 500){
                if(count == 0){
                    time = Date.now();
                    CountNumb();
                }
            }
        });
    }

    function CountNumb(){
        if(document.querySelectorAll('.number_year')){
            const now = Date.now();
            const deltaTime = (now - time) / 1000;
            elapsedTime += deltaTime;
            time = now;
            
            count++;            
            setTimeAnimation(Math.min(lerp(0, 1, elapsedTime / animationDuration), 1));

            if(elapsedTime < animationDuration){
                window.requestAnimationFrame(CountNumb);
            }            
        }
    }

    function lerp (start, end, amt){
        return (1-amt) * start + amt * end
    }

    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
        document.addEventListener('scroll', (event)=>{
            ScrollAnimation();
        });
    }, []);
    
    return(
        <div className="Comp_ShowNumbers">
            {
                showData.mask_top[idioma] == 0 ? null : <Mask className="mask_top" color={ colorTop } />
            }

            {
                showData.mask_bottom[idioma] == 0 ? null : <Mask className="mask_bottom" color={ colorBottom } />
            }

            <div className="div_number">
                <div className="year">
                    <div className="number_year" data-animationcount="count">{ Math.round(timeAnimation * showData.year.number[idioma]) }</div>
                    <div className="name" dangerouslySetInnerHTML={ { __html: showData.year.name[idioma] ? showData.year.name[idioma].replaceAll('&#34;', '"') : "" } } />
                </div>
                <div className="list_number">
                    {
                        listBtn.map((elem, index)=>{
                            return(
                                <div className="show_number" key={ index }>
                                    <div className="div_data">
                                        <div className="number sigla">{ elem.sigla }</div>
                                        <div className="number" data-animationcount="count">{ Math.floor(timeAnimation * elem.number) }</div>
                                    </div>
                                    <div className="name" dangerouslySetInnerHTML={ { __html: elem.name ? elem.name.replaceAll('&#34;', '"') : "" } } />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}