import { useState, useEffect } from "react";

import './Video.css';

import { GetListPag, RegisterListPag } from "interface/Page";

import Mask from "components/Mask";

export default function Comp_Video(props){

    const [ idioma, setIdioma ] = useState(GetListPag('idioma'));
    const [ showData, setShowData ] = useState(InitialData('current'));

    const [ colorTop, setColorTop ] = useState(InitialData('colorTop'));
    const [ colorBottom, setColorBottom ] = useState(InitialData('colorBottom'));

    function InitialData(type){
        if(type == 'current'){
            const newData = props.data.find(item => item.id == props.id);
            return newData;
        }else {
            if(type == 'colorTop'){
                if((props.index - 1) >= 0){
                    const newData = props.data[(props.index - 1)];
                    return newData.color_page[idioma];
                }else {
                    const newData = props.data.find(item => item.id == props.id);
                    return newData.color_page[idioma];
                }

            }else if(type == 'colorBottom'){
                if((props.index + 1) < props.data.length){
                    const newData = props.data[(props.index + 1)];
                    return newData.color_page[idioma];
                }else {
                    const newData = props.data.find(item => item.id == props.id);
                    return newData.color_page[idioma];
                }
            }
        }
    }
    
    function ShowVideo(type, video){
        switch (type) {
            case 'Vimeo':
                return <iframe className="div_video" src={ "https://player.vimeo.com/video/" + video + "" } frameBorder="0" allow="fullscreen; picture-in-picture" />;

            case 'Drive':
                let linkDrive = video.split('/');
                return <iframe className="div_video" src={ "https://drive.google.com/file/d/" + linkDrive[5] + "/preview" } frameBorder="0" allow="fullscreen; picture-in-picture" />;    
            
            default:
                return <iframe className="div_video" src={ "https://www.youtube.com/embed/" + video } frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen="" />;
        }
    }

    function ScrollAnimation(){
        document.querySelectorAll('.efect_translate_y').forEach(element => {
            if(element.getBoundingClientRect().top <= 500){
                element.classList.add('efect_translate_visible');
            }
        });
    }

    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
        if(props.index !=0){
            document.addEventListener('scroll', (event)=>{
                ScrollAnimation();
            });
        }
    }, []);
    
    return(
        <div className="Comp_Video">
            {
                showData.mask_top[idioma] == 0 ? null : <Mask className="mask_top" color={ colorTop } />
            }

            {
                showData.mask_bottom[idioma] == 0 ? null : <Mask className="mask_bottom" color={ colorBottom } />
            }

            {
                showData.subtitle[idioma] == '' || showData.title[idioma] == '' ? null :
                <div className={ props.index !=0 ? "div_text div_right efect_translate_y" : "div_text div_right" }>
                    <div className="show_text">
                        {
                            showData.subtitle[idioma] !='' ? 
                            <div className="subtitle">{ showData.subtitle[idioma] }</div> : null
                        }
                        {
                            showData.title[idioma] !='' ?
                            <div className="title" dangerouslySetInnerHTML={ { __html: showData.title[idioma] ? showData.title[idioma].replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } /> : null
                        }
                    </div>
                </div>
            }
            <div className={ props.index !=0 ? "list_video efect_translate_y" : "list_video" }>
                <div className="show_video">
                    { ShowVideo(showData.type[idioma], showData.video[idioma]) }
                </div>
            </div>
        </div>
    )
}