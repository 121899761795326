import { useState, useEffect } from "react";

import { SetListPag } from "interface/Page";
import { GetDataPage } from "interface/Data";
import { SetModalData, SetModalState } from "interface/PopUp";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Comp_Header from "components/Header";
import Comp_Animation from "components/Animation";
import Comp_LineFixed from "components/LineFixed";

import Page_Home from "pages/Home";
import Page_About from "pages/About";
import Page_Works from "pages/Works";
import Page_Contact from "pages/Contact";
import Page_Preview from "pages/Preview";
import PopUP_ReturnResponse from "components/PopUp/ReturnResponse";

export default function App() {

    const [ loading, setLoading ] = useState(false);

    function OpenFile(type, file){
        if(file !=''){
            SetModalData('ShowFile', { "type": type, "file": file });
            SetModalState('ShowFile', true);
        }
    }

    function CallbackSuccess(value){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok", "text": value });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(value){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro", "text": value });
        SetModalState('ReturnResponse', true);
    }

    function ReturnTop(value){
        window.scrollTo(0, 0);
        SetListPag('page', value);
        
        document.querySelectorAll('.efect_translate_y').forEach(element => {
            if (element.classList.contains('efect_translate_visible')) {                
                element.classList.remove('efect_translate_visible');
            }
        });
    }  

    useEffect(()=>{
        if(loading){
            document.body.style.overflow = 'hidden';
        }else {
            document.body.style.overflow = 'auto';
        }
    }, [loading]);

    return(
        <>
            <div className={ loading ? "return_data_save div_loading" : "return_data_save" }>
                <div className="dot-spinner">
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                </div>
            </div>

            <div className="show_data_page">
                <div className="whatsapp">
                    <a href="https://wa.me/+554192583829" target="_blank">
                        <img alt="Whatsapp" src="./assets/whatsapp.png" className="icon_whatsapp" />
                    </a>
                </div>

                <Comp_Animation />
                <Comp_LineFixed />

                <Comp_Header ReturnTop={ ReturnTop } />

                <Routes>                    
                    <Route exact path="/" element={ <Page_Home setLoading={ setLoading } ReturnTop={ ReturnTop } /> } />
                    <Route exact path="/home" element={ <Page_Home setLoading={ setLoading } ReturnTop={ ReturnTop } /> } />
                    
                    <Route exact path="/about" element={ <Page_About setLoading={ setLoading } ReturnTop={ ReturnTop } /> } />
                    
                    <Route exact path="/contact" element={ <Page_Contact setLoading={ setLoading } ReturnTop={ ReturnTop } /> } />

                    <Route exact path="/works" element={ <Page_Works setLoading={ setLoading } ReturnTop={ ReturnTop } /> } />

                    {
                        GetDataPage('portfolio')['portfolio'].map((elem, index)=>{
                            return(
                                <Route exact path={ "/" + elem.url } element={ <Page_Preview url={ elem.url } setLoading={ setLoading } ReturnTop={ ReturnTop } /> } key={ index } />
                            )
                        })
                    }
                </Routes>
            </div>
            <PopUP_ReturnResponse setLoading={ setLoading } />
        </>
    );
}