import { useState, useEffect } from "react";

import './Animation.css';

export default function Comp_Animation(props){

    const [ countPerc, setCountPerc ] = useState(0); 

    function ShowCountPercentage(){
        let count = 0;
        let showCount = setInterval(() => {
            count++;
            setCountPerc(count);
            if(count == 100){
                // hide count percentage
                setTimeout(()=>{
                    document.querySelector('.anim_show_percentage').classList.add('anim_show_percentage_animation');                    
                }, 200);

                // hide line
                setTimeout(()=>{
                    document.querySelector('.anim_line_1').classList.add('anim_hide_color_line');
                }, 400);
                setTimeout(()=>{
                    document.querySelector('.anim_line_2').classList.add('anim_hide_color_line');
                }, 200);
                setTimeout(()=>{
                    document.querySelector('.anim_line_3').classList.add('anim_hide_color_line');
                }, 300);
                setTimeout(()=>{
                    document.querySelector('.anim_line_4').classList.add('anim_hide_color_line');
                }, 500);

                // alt z-index
                setTimeout(() => {
                    document.querySelector('.Animation').classList.add('hide_animation_initial');
                }, 2000);
                clearInterval(showCount);                
            }
        }, 16);
    }

    useEffect(()=>{
        ShowCountPercentage()
    }, []);

    return(
        <div className="Animation">
            <div className="anim_line_color anim_line_1">
                <div className="anim_color_1" />
            </div>
            <div className="anim_line_color anim_line_2">
                <div className="anim_color_2" />
            </div>
            <div className="anim_line_color anim_line_3">
                <div className="anim_color_3">
                    <div className="anim_show_percentage" >
                        { 
                            countPerc < 10 ? "00" + countPerc : 
                                countPerc < 100 ?  "0" + countPerc : countPerc 
                        } 
                        <span className="anim_span_percent">%</span>
                    </div>
                </div>
            </div>
            <div className="anim_line_color anim_line_4">
                <div className="anim_color_4" />
            </div>
        </div>
    )
}