import { useState, useEffect } from "react";

import './Contact.css';

import TextareaAutosize from 'react-textarea-autosize';

import { Reg_Contact } from "services";

import { GetListPag, RegisterListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { SetModalData, SetModalState } from "interface/PopUp";

import Comp_Footer from "components/Footer";

export default function Page_Contact(props){

    const [ page, setPage ] = useState(GetListPag('page'));
    const [ idioma, setIdioma ] = useState(GetListPag('idioma'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('contact'));

    const [ newClassAdd, setNewClassAdd ] = useState('animation');

    const [ name, setName ]       = useState('');
    const [ email, setEmail ]     = useState('');
    const [ phone, setPhone ]     = useState('');
    const [ subject, setSubject ] = useState('');
    const [ text, setText ]       = useState('');
    const [ privacy, setPrivacy ] = useState(false);

    const [ textFixed, setTextFixed ] = useState([
        {
            "en": "Name",
            "es": "Nombre",
            "pt_br": "Nome"
        },
        {
            "en": "Who are you?",
            "es": "¿Quién eres?",
            "pt_br": "Quem é você?"
        },
        {
            "en": "Email",
            "es": "Correo electrónico",
            "pt_br": "E-mail"
        },
        {
            "en": "You'll not see any ads",
            "es": "No verás ningún anuncio.",
            "pt_br": "Você não verá nenhum anúncio"
        },
        {
            "en": "Phone",
            "es": "Teléfono móvil",
            "pt_br": "Celular"
        },
        {
            "en": "Promise we'll not disturb",
            "es": "Prometo que no molestaremos",
            "pt_br": "Prometa que não vamos incomodar"
        },
        {
            "en": "Subject",
            "es": "Sujeto",
            "pt_br": "Assunto"
        },
        {
            "en": "This can be just love.",
            "es": "Esto puede ser simplemente amor.",
            "pt_br": "Isso pode ser apenas amor."
        },
        {
            "en": "Message",
            "es": "Mensaje",
            "pt_br": "Mensagem"
        },
        {
            "en": "Looking forward for your precious words...",
            "es": "Esperando sus preciosas palabras...",
            "pt_br": "Aguardo suas preciosas palavras..."
        },
        {
            "en": "Accept Privacy",
            "es": "Aceptar Privacidad",
            "pt_br": "Aceitar privacidade"
        },
        {
            "en": "To save",
            "es": "Ahorrar",
            "pt_br": "Salvar"
        }
    ]);

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_Contact(name, email, phone, subject, text, privacy, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        setName('');
        setEmail('');
        setPhone('');
        setSubject('');
        setText('');
        setPrivacy(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ScrollAnimation(){
        // const windowTop = document.documentElement.scrollTop + (document.documentElement.clientHeight * 2) / 4;
        document.querySelectorAll('[data-animationtext]').forEach(element => {
            if(element.getBoundingClientRect().top <= 500){
                element.classList.add(newClassAdd);
            }
        });
    }

    useEffect(()=>{
        RegisterListPag('page', setPage);
        RegisterListPag('idioma', setIdioma);
        document.addEventListener('scroll', (event)=>{
            ScrollAnimation();
        });
    }, []);
    
    return(
        <>
            <div className="Page_Contact">
                <div className="Comp_Text">
                    <div className="div_text div_right">
                        <div className="show_text">
                            {
                                dataPage[0].subtitle[idioma] == "" ? null : 
                                <div className="subtitle">{ dataPage[0].subtitle[idioma] }</div>
                            }
                            {
                                dataPage[0].title[idioma] == "" || dataPage[0].title[idioma] == "<p><br></p>" ? null : 
                                <div className="title" dangerouslySetInnerHTML={ { __html: dataPage[0].title[idioma] ? dataPage[0].title[idioma].replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                            }
                            {
                                dataPage[0].text[idioma] !='' ? 
                                <div className={ props.temp == 1 ? "div_show_text new_align_text" : "div_show_text" }>
                                    <div className={ props.temp == 1 ? "text new_text_css" : "text" } dangerouslySetInnerHTML={ { __html: dataPage[0].text[idioma] ? dataPage[0].text[idioma].replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                </div> : null
                            }
                        </div>
                    </div>
                    <div className="contents">
                        <form onSubmit={ SaveData } className="form">
                            <div className="div_input">
                                <div className="name_input">{ textFixed[0][idioma] }</div>
                                <div className="show_div_input">
                                    <input type="text" className="show_input" onChange={ (e)=>{ setName(e.target.value) } } value={ name } placeholder={ textFixed[1][idioma] } />
                                </div>
                            </div>

                            <div className="div_input">
                                <div className="name_input">{ textFixed[2][idioma] }</div>
                                <div className="show_div_input">
                                    <input type="text" className="show_input" onChange={ (e)=>{ setEmail(e.target.value) } } value={ email } placeholder={ textFixed[3][idioma] } required />
                                </div>
                            </div>

                            <div className="div_input">
                                <div className="name_input">{ textFixed[4][idioma] }</div>
                                <div className="show_div_input">
                                    <input type="text" className="show_input" onChange={ (e)=>{ setPhone(e.target.value) } } value={ phone } placeholder={ textFixed[5][idioma] } />
                                </div>
                            </div>

                            <div className="div_input">
                                <div className="name_input">{ textFixed[6][idioma] }</div>
                                <div className="show_div_input">
                                    <input type="text" className="show_input" onChange={ (e)=>{ setSubject(e.target.value) } } value={ subject } placeholder={ textFixed[7][idioma] } />
                                </div>
                            </div>

                            <div className="div_input">
                                <div className="name_input">{ textFixed[8][idioma] }</div>
                                <div className="show_div_input">
                                    <TextareaAutosize className="show_input" onChange={ (e)=>{ setText(e.target.value) } } placeholder={ textFixed[9][idioma] } value={ text } />
                                </div>
                            </div>
                            
                            <div className="div_form_submit">
                                <div className="div_checkbox">
                                    <div className="">
                                        <input type="checkbox" value="true" onChange={ (e)=>{ setPrivacy(!privacy) } } checked={ privacy } required />
                                    </div>
                                    <div className="show_div_input">
                                        <div className="name_input">{ textFixed[10][idioma] }</div>
                                    </div>
                                </div>
                                <button type="submit" className="btn">
                                    { textFixed[11][idioma] }
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <Comp_Footer ReturnTop={ props.ReturnTop } />
        </>
    )
}