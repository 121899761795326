import { useState, useEffect } from "react";

import './Home.css';

import { GetListPag, RegisterListPag } from "interface/Page";
import { SvgBehance, SvgInstagram_1, SvgYoutube } from "components/SvgFile";

import Mask from "components/Mask";

import { Link } from "react-router-dom";

export default function Comp_Home(props){

    const [ idioma, setIdioma ] = useState(GetListPag('idioma'));
    const [ showData, setShowData ] = useState(InitialData('current'));

    const [ colorTop, setColorTop ] = useState(InitialData('colorTop'));
    const [ colorBottom, setColorBottom ] = useState(InitialData('colorBottom'));

    const [ textFixed, setTextFixed ] = useState({
        "en": "let's talk",
        "es": "hablemos",
        "pt_br": "vamos conversar"
    });

    function InitialData(type){
        if(type == 'current'){
            const newData = props.data.find(item => item.id == props.id);
            return newData;
        }else {
            if(type == 'colorTop'){
                if((props.index - 1) >= 0){                    
                    const newData = props.data[(props.index - 1)];
                    return newData.color_page[idioma];
                }else {
                    const newData = props.data.find(item => item.id == props.id);
                    return newData.color_page[idioma];
                }

            }else if(type == 'colorBottom'){
                if((props.index + 1) < props.data.length){
                    const newData = props.data[(props.index + 1)];
                    return newData.color_page[idioma];
                }else {
                    const newData = props.data.find(item => item.id == props.id);
                    return newData.color_page[idioma];
                }
            }
        }
    }
    
    function ShowVideo(type, video){
        switch (type) {
            case 'Vimeo':
                return(
                    <div className="video_background">
                        <div className="video_foreground">
                            <iframe src={ "https://player.vimeo.com/video/" + video + "?&amp;autoplay=1&amp;loop=1&amp;playlist=" + video + "&amp;rel=0&amp;controls=0&amp;mute=1" } frameBorder="0" allowFullScreen></iframe>
                        </div>
                    </div>
                )

            case 'Youtube':
                return(
                    <div className="video_background">
                        <div className="video_foreground">
                            <iframe src={ "https://www.youtube.com/embed/" + video + "?&amp;autoplay=1&amp;loop=1&amp;playlist=" + video + "&amp;rel=0&amp;controls=0&amp;mute=1" } frameBorder="0" allowFullScreen></iframe>
                        </div>
                    </div>
                )
        }
    }

    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
        setTimeout(() => {
            if(document.querySelector('.show_rede_social')){
                document.querySelector('.show_rede_social').style.zIndex = '11';
            }
        }, 4500);
    }, []);
    
    return(
        <div className="Comp_Home">
            {
                showData.mask_top[idioma] == 0 ? null : <Mask className="mask_top" color={ colorTop } />
            }

            {
                showData.mask_bottom[idioma] == 0 ? null : <Mask className="mask_bottom" color={ colorBottom } />
            }

            <div className="div_text">
                <div className="show_title" dangerouslySetInnerHTML={ { __html: showData.title[idioma] ? showData.title[idioma].replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                <div className="show_text" dangerouslySetInnerHTML={ { __html: showData.text[idioma] ? showData.text[idioma].replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
            </div>
                
            <div className="div_video_or_img">
                <div className="dotted" />
                <div className="show_rede_social">
                    <div className="list_data_rede_social">
                        <Link to="/contact" onClick={ ()=>{  props.ReturnTop('contact') } } className="name_click">
                            { textFixed[idioma] }
                        </Link>
                        <div className="div_rede_social">
                            <div className="div_icons_social">
                                <a href="https://www.youtube.com/user/chromagarden" target="_blank">
                                    <SvgYoutube className="icons_rede" color="#ffffff" />
                                </a>
                            </div>
                            <div className="div_icons_social">
                                <a href="https://www.instagram.com/chromagarden/" target="_blank">
                                    <SvgInstagram_1 className="icons_rede" color="#ffffff" />
                                </a>
                            </div>
                            <div className="div_icons_social">
                                <a href="https://www.behance.net/chromagarden" target="_blank">
                                    <SvgBehance className="icons_rede" color="#ffffff" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    showData.type[idioma] == "video" ? 
                        ShowVideo(showData.type_video[idioma], showData.file[idioma]) : 
                        <img alt="img" src={ "" } className="show_full" />
                }
            </div>
        </div>
    )
}