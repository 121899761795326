import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import Axios from 'axios';

import { SetListData } from 'interface/Data';

import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
const data = new FormData();
data.append('type_page', 'show_data');

Axios({
    url : process.env.REACT_APP_API_URL + 'list_data.php',
    mode : 'no-cors',
    method : 'POST',
    data : data
})
.then(response => {
    console.log(response.data);
    SetListData(response.data);
    
    root.render(
        <BrowserRouter>
            <App />
        </BrowserRouter>,
        document.getElementById('root')
    ); 
});