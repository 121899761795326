import { useState, useEffect } from "react";

import './Works.css';

import { GetListPag } from "interface/Page";
import { GetDataPage } from "interface/Data";

import Comp_Home from "components/Home";
import Comp_Text from "components/Text";
import Comp_Video from "components/Video";
import Comp_Clients from "components/Clients";
import Comp_Spotlight from "components/Spotlight";
import Comp_Portfolio from "components/Portfolio";
import Comp_ShowNumbers from "components/ShowNumbers";
import Comp_OurServices from "components/OurServices";
import Comp_Footer from "components/Footer";

export default function Page_Works(props){

    const [ idioma, setIdioma ] = useState(GetListPag('idioma'));
    const [ showData, setShowData ] = useState(GetDataPage('works'));
    
    function CurrentPage(type_page, id, index){
        switch (type_page) {
            case 'home':
                return <Comp_Home data={ showData } id={ id } ReturnTop={ props.ReturnTop } index={ index } />;

            case 'our_services':
                return <Comp_OurServices data={ showData } id={ id } ReturnTop={ props.ReturnTop } index={ index } />;

            case 'spotlight':
                return <Comp_Spotlight data={ showData } id={ id } ReturnTop={ props.ReturnTop } index={ index } />;
                
            case 'clients':
                return <Comp_Clients data={ showData } id={ id } ReturnTop={ props.ReturnTop } index={ index } />;
                
            case 'text':
                return <Comp_Text data={ showData } id={ id } temp='' ReturnTop={ props.ReturnTop } index={ index } />;
                
            case 'show_numbers':
                return <Comp_ShowNumbers data={ showData } id={ id } ReturnTop={ props.ReturnTop } index={ index } />;
                
            case 'video':
                return <Comp_Video data={ showData } id={ id } ReturnTop={ props.ReturnTop } index={ index } />;
        }
    }
    
    return(
        <>
            <div className="Page_Works">
                {
                    showData.map((elem, index)=>{
                        return(
                            <div className="section" key={ index } style={ { backgroundColor: elem.color_page[idioma] } }>
                                { CurrentPage(elem.type_page, elem.id, index) }
                            </div>
                        )
                    })
                }
                <Comp_Portfolio origin="portfolio" data={ GetDataPage('portfolio') } ReturnTop={ props.ReturnTop } />
            </div>

            <Comp_Footer ReturnTop={ props.ReturnTop } />
        </>
    )
}