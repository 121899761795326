import { useState, useEffect } from "react";

import './Footer.css';

import { GetDataPage } from "interface/Data";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";
import { SvgBehance, SvgInstagram_1, SvgYoutube } from "components/SvgFile";

import { useNavigate } from 'react-router-dom';

import { Link } from "react-router-dom";

export default function Comp_Footer(props){

    const navigate = useNavigate();
    const [ idioma, setIdioma ] = useState(GetListPag('idioma'));
    const [ namePage, setNamePage ] = useState(GetDataPage('name_menu'));

    const [ translate, setTranslate ] = useState([
        {
            "en": "Let Us Help You Captivate Your Audience",
            "es": "Permítanos ayudarlo a cautivar a su audiencia",
            "pt_br": "Deixe-nos ajudá-lo a cativar seu público"
        },
        {
            "en": "Get a Quote",
            "es": "Consigue una cotización",
            "pt_br": "Faça uma cotação"
        },
        {
            "en": "Privacy Policy",
            "es": "Política de privacidad",
            "pt_br": "Política de Privacidade"
        },
        {
            "en": "All rights reserved",
            "es": "Todos los derechos reservados",
            "pt_br": "Todos os direitos reservados"
        }
    ]);

    function OpendProject(page){
        navigate("/" + page);
        SetListPag('page', page);
    }
    
    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
    }, []);
    
    return(
        <div className="Comp_Footer">        
            <div className="line_top">
                <div className="line_ft_1" />
                <div className="line_ft_2" />
                <div className="line_ft_3" />
                <div className="line_ft_4" />
            </div> 
            
            {
                props.url ?
                <div className="div_img">
                    <div className="project">{ props.project }</div>
                    <div className="project_subtitle">{ props.subtitle }</div>
                    <Link to={ "/" + props.url } onClick={ ()=>{ props.setShowUrl(props.url) } } className="show_preview">
                        <img src={ props.file } className="img" />
                    </Link>
                </div>
                : 
                <div className="div_img">
                    <Link to="/" onClick={ ()=>{  props.ReturnTop('home') } } className="align_logo">
                        <img src="./assets/02.png" className="img_logo" />
                    </Link>
                </div>
            }

            <div className="div_data_footer contents">
                <div className="show_data">
                    <div className="div_btn">
                        <div className="title">{ translate[0][idioma] }</div>
                        <Link to="/contact" onClick={ ()=>{  props.ReturnTop('contact') } } className="btn">
                            { translate[1][idioma] }
                        </Link>
                    </div>
                    <div className="div_name_page">
                        <div className="title_click"></div>
                        {
                            namePage.map((elem, index)=>{
                                return(
                                    <div className="name_page" key={ index }>
                                        <Link to={ "/" + elem.href } onClick={ ()=>{  props.ReturnTop(elem.href) } }>
                                            { elem.title[idioma] }
                                        </Link>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <div className="div_data_rede_social">
                    <div className="width_fixed origin">
                       { translate[2][idioma] }<br /> CNPJ 20.817.641/0001-40
                    </div>
                    <div className="width_fixed origin_center">
                        Copyright ©ChromaGarden<br /> { translate[3][idioma] }
                    </div>
                    <div className="width_fixed show_rede_social">
                        <a href="https://www.youtube.com/user/chromagarden" className="rede_social">
                            <SvgYoutube className="icons" color="#ededed" />
                        </a>
                        <a href="https://www.instagram.com/chromagarden/" className="rede_social">
                            <SvgInstagram_1 className="icons" color="#ededed" />
                        </a>
                        <a href="https://www.behance.net/chromagarden" className="rede_social">
                            <SvgBehance className="icons" color="#ededed" />
                        </a>
                    </div>
                </div>
            </div> 
            
            <div className="line_bottom">
                <div className="line_ft_1" />
                <div className="line_ft_2" />
                <div className="line_ft_3" />
                <div className="line_ft_4" />
            </div>
        </div>
    )
}